<template>
    <div class="zt-page-content">
        <div style="margin:20px;text-align:right;">
            <el-button size="small" type="primary" @click="add('产线规划送审与验收申请')">提交新申请</el-button>
        </div>
        <div class="block" style="height: calc(100% - 92px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="申请编号">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="申请日期">
                        <template slot-scope="scope">
                            <div>{{scope.row.createdAt | moment("YYYY-MM-DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="factory_addr" label="厂房地址">
                        <template slot-scope="scope">
                            <div>{{scope.row.info.factory_addr}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="prod_line_plan_desc" label="设计公司">
                        <template slot-scope="scope">
                            <div>{{scope.row.info.design_company}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="申请状态">
                        <template>
                            <div>已提交</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page.pageNumber" :page-size="queryOpt.page.pageCount" :total="total"></el-pagination>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <Detail :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></Detail>
        </el-dialog>
    </div>
</template>

<script>
    import Detail from './SjfsqDetail'
    import { applyList } from '@/service/factory';
    export default {
        components: {
            Detail
        },
        data() {
            return {
                popVisible: false,
                popTitle: null,
                queryOpt:{
                    where:{
                        type:"prod_plan"
                    },
                    page:{
                        pageNumber:1,
                        pageCount:15,
                    },
                    order: [
                        ["createdAt","desc"]
                    ],
                },
                total:0,
                list:[],
                comp:null,
                ruleForm:"",
            }
        },
        async created() {
            this.comp = this.$store.state.loginUser.comp;
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                applyList(this.queryOpt).then(rst => {
                    if (rst && rst.rows.length>0) {
                        this.list = rst.rows;
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add(text){
                this.editing=true;
                this.popTitle=text;
                this.popVisible=true;
                if (this.popTitle == '产线规划送审与验收申请') {
                    this.ruleForm = {
                        type:"prod_plan",
                        info:{
                            company_name:this.comp.name,
                            legal_name:this.comp.legal_name,
                            phone:"",
                            factory_addr:"",
                            design_company:"",
                            prod_line_plan_type:"",
                        },
                        submitNow:true,
                    }
                }
            },
            view(row) {
                this.editing=false;
                this.ruleForm=row;
                this.popTitle='查看';
                this.popVisible=true;
            },
            edit(row) {
                this.editing=true;
                this.ruleForm=row;
                this.popTitle='编辑';
                this.popVisible=true;
            },
            del(row) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type:'warning'
                }).then(() => {

                    this.$message.success('删除成功!');
                });
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>

    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-dialog {
        min-width: 550px;
    }
    div>>> .el-dialog__body {
        background: #F5F5F5;
    }

</style>